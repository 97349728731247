<template>
    <div id="eskipass-meter-app" >
        <div class="container">
            <WtpForm v-bind:resort="resort" v-bind:locale="locale" />
<!--            <WtpForm resort="dev" locale="cs" />-->
        </div>
    </div>
</template>

<script>
    import WtpForm from './components/WtpForm'

    export default {
        name: 'App',
        props: {
            'resort': String,
            'locale': String,
        },
        components: {
            WtpForm
        }
    }
</script>

<style lang="scss">
    #eskipass-meter-app {
        font-family: sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;

        $table-accent-bg: #f8f9fa;
        $font-size-base: 1rem;
        $border-radius: 0rem;
        $primary: #000;
        font-size: 1rem !important;

      @import '../node_modules/bootstrap/dist/css/bootstrap.css';
      @import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
      @import '../node_modules/v-calendar/lib/v-calendar.min.css';

    }
</style>


<template>
  <div>
    <div class="row justify-content-center">
      <form @submit.prevent="submit" id="wtp-form">
        <fieldset>
          <div class="mt-3"><label for="chip-id">{{ $t('form.wtpCode') }}</label></div>
          <div class="text-center">
            <b-input
                id="wtp-no"
                v-model="form.wtp_no"
                :formatter="format"
                :required="true"
                autocomplete="off"
                spellcheck="false"
                class="mx-auto"
                placeholder="00000000-000-000"
            />
          </div>

          <div class="text-danger small mt-1" v-for="err in errors" :key="err.code">
            {{ err.message }}
          </div>

          <div class="pt-3 pb-2"><strong>{{ $t('form.period') }}</strong></div>
          <b-form-group>
            <b-col sm="11">
              <v-date-picker
                  mode='range'
                  v-model='dateRange'
                  show-caps
                  is-double-paned
                  is-inline
                  tint-color="#000"
                  :theme-styles='themeStyles'
                  :attributes.sync='attributes'
              >
              </v-date-picker>
            </b-col>
          </b-form-group>

          <b-form-group>
            <b-button variant="dark" type="submit" :disabled="submitting" id="submit-em">
              {{ $t('form.submit') }}
            </b-button>
          </b-form-group>
        </fieldset>
      </form>
    </div>

    <div class="clearfix mt-2"></div>
    <div class="spinner-border" role="status" v-if="submitting">
      <span class="sr-only">Loading...</span>
    </div>

    <p v-if="submitting" class="small mt-2"> {{ $t('form.loading') }}</p>

    <PeriodTable v-if="dataRecieved" v-bind:rides-data="ridesData"/>
  </div>
</template>

<script>
import Vue from 'vue';
import PeriodTable from "@/components/PeriodTable";
import axios from 'axios';
import 'vue-awesome/icons/search';
import Icon from 'vue-awesome/components/Icon';
import VueI18n from 'vue-i18n';
import translations from "../resources/translations";
import VCalendar, {setupCalendar} from 'v-calendar';
import BootstrapVue from "bootstrap-vue";

Vue.component('v-icon', Icon);
Vue.use(BootstrapVue)
Vue.use(VueI18n);
Vue.use(VCalendar, {
  firstDayOfWeek: 2,  // Monday
  locale: 'cs'
});

let dateTo = new Date();
dateTo.setHours(23, 59, 59, 0);
let dateFrom = new Date();
dateFrom.setHours(0, 0, 0, 0);
dateFrom.setDate(dateTo.getDate() - 30);

export default {
  components: {
    PeriodTable
  },
  name: 'WtpForm',
  props: {
    'resort': String,
    'locale': String,
  },
  i18n: new VueI18n({
    locale: 'cs',
    fallbackLocale: 'cs',
    messages: translations
  }),
  data() {
    return {
      isSubmitted: false,
      isError: false,
      errorMsg: '',
      errors: [],
      attributes: [],
      submitting: false,
      form: {
        wtp_no: '',
        date_from: '',
        date_to: ''
      },
      dataRecieved: false,
      ridesData: {},
      themeStyles: {
        wrapper: {
          background: '#f8f9fa',
          border: '1px solid #ced4da'
        }
      },
      dateRange: {
        start: dateFrom,
        end: dateTo
      }
    }
  },
  computed: {
    backendUrl() {
      if (this.resort === 'dev') {
        return 'http://resort-card.docker4:800'
      }

      return 'https://' + this.resort + '-be.e-skipass.cz'
    }
  },
  created() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'resort': this.resort,
        'locale': this.locale,
      }
    });
    this.$i18n.locale = this.locale;

    setupCalendar({
      locale: this.locale ? this.locale : "en",
    });
  },
  methods: {
    format(value, event) {
      value = value.replace(' ', '')
      if (value.toLowerCase().substr(value, 4) === 'http') {
        const matchedWtp = this.matchWtp(value)
        if (matchedWtp !== null) {
          value = matchedWtp
        }
      } else if (
          (value.length === 8 || value.length === 12) &&
          event.inputType === 'insertText'
      ) {
        value += '-'
      } else if (value.length === 14 && event.inputType === 'insertFromPaste') {
        value =
            value.substr(0, 8) +
            '-' +
            value.substr(8, 3) +
            '-' +
            value.substr(11, 3)
      } else {
        value = value.replace('--', '-')
      }

      return value.toUpperCase().substr(0, 16)
    },
    matchWtp(value) {
      const search = new RegExp(
          '[A-Za-z0-9]{8}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}',
          'i'
      ).exec(value)
      if (search !== null) {
        return search[0]
      }
      return null
    },
    submit() {
      this.errors = [];
      this.isError = false;
      this.errorMsg = '';
      this.dataRecieved = false;
      this.ridesData = {};
      this.form.date_from = this.dateRange.start;
      this.form.date_to = this.dateRange.end;
      this.sendFormData();
    },
    enableSubmitLoader() {
      this.submitting = true;
    },
    disableSubmitLoader() {
      this.submitting = false;
    },
    sendFormData() {
      this.enableSubmitLoader();
      axios.get(
          this.backendUrl + '/api/pub/stats/wtp',
          {
            params: {
              wtpNo: this.form.wtp_no,
              dateFrom: this.form.date_from.toISOString().slice(0, 10),
              dateTo: this.form.date_to.toISOString().slice(0, 10),
            }
          },
          {
            headers: {
              'resort': this.resort,
              'locale': this.locale,
            }
          },
      ).then(response => {
        this.submitSuccess(response);
        this.disableSubmitLoader();
      }).catch(error => {
        this.submitError(error);
        this.disableSubmitLoader();
      });
    },
    submitSuccess(response) {
      if (typeof response.data.errors === "undefined" || response.data.errors.length === 0) {
        this.isSubmitted = true;
        this.isError = false;
        if (typeof response.data.days !== "undefined") {
          this.dataRecieved = true;
          this.ridesData = response.data;
          this.setDates();
        } else {
          this.submitError();
        }
      } else {
        this.errors = response.data.errors;
        this.isError = true;
      }
    },
    submitError() {
      this.errors = [{'field': null, 'message': this.$t('error.generalMessage')}];
      this.isError = true;
    },

    isErrorField(field) {
      return this.errors.some(el => el.field === field);
    },
    getFieldClasses(field) {
      return {'is-invalid': this.isErrorField(field)}
    },
    setDates() {
      if (this.dataRecieved === false) {
        return [];
      }
      let days = [];
      let i;
      for (i = 0; i < this.ridesData.days.length; i++) {
        days[i] = new Date(this.ridesData.days[i].date * 1000);
      }
      this.attributes =
          [
            {
              highlight: {
                backgroundColor: '#00C5D1',
                borderColor: '#00C5D1',
                borderWidth: '0px',
                borderStyle: 'solid',
              },
              contentStyle: {
                color: 'white',
              },
              dates: days

            }
          ];
    }
  },
  watch: {
    errors() {
      this.isError = this.errors.length > 0;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
form#wtp-form {
  input.form-control {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  input#wtp-no {
    max-width: 250px;
    text-align: center;
  }

  label {
    font-weight: bold;
  }

  .input-group {
    width: 347px;
  }
}
</style>

export default {
    cs: {
        form: {
            wtpCode: "WTP kód karty",
            period: "Období",
            submit: "Odeslat",
            loading: "Načítám data...",
        },
        error: {
            generalMessage: "Pro zadané WTP nebyl nalezen žádný záznam."
        },
        total: "Celkové skóre za zvolené období",
        stats: {
          rides: "jízd",
          vertical: "převýšení",
          slope: "na sjezdovce",
          cable: "na lanovce",

        },
        days: "Výsledky po dnech",
        table: {
            date: "Datum",
            rides: "Počet jízd",
            vertical: "Převýšení",
            slope: "Na sjezdovce",
            cable: "Na lanovce",

        }
    },
    sk: {
        form: {
            wtpCode: "WTP kód karty",
            period: "Obdobie",
            submit: "Odoslať",
            loading: "Načítám data...",
        },
        error: {
            generalMessage: "Pre zadané WTP nebol nájdený žiadny záznam."
        },
        total: "Celkové skóre za zvolené obdobie",
        stats: {
            rides: "jázd",
            vertical: "prevýšenie",
            slope: "na zjazdovke",
            cable: "na lanovke",
        },
        days: "Výsledky po dňoch",
        table: {
            date: "Dátum",
            rides: "Počet jázd",
            vertical: "Prevýšenie",
            slope: "Na zjazdovke",
            cable: "Na lanovke",

        }
    }
};
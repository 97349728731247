<template>
    <b-card no-body>
    <GChart
            :settings="{'packages':['corechart'], 'language': 'cs'}"
            type="LineChart"
            :data="chartData"
            :options="chartOptions"
    />
    </b-card>
</template>

<script>
    import {GChart} from 'vue-google-charts'

    export default {
        name: 'DayChart',
        components: {
            GChart
        },
        props: ['day'],
        data() {
            return {
                chartOptions: {
                    chart: {
                        title: 'Rides and drops',
                        subtitle: ''
                    },
                    legend: 'none',
                    pointSize: 6,
                    tooltip: { isHtml: true },
                    colors: ['#000']
                }
            }
        },
        computed: {
            chartData() {
                let data = [];
                let i;
                console.log(this.day)
                for (i = 0; i < this.day.length; i++) {
                    const date = new Date(this.day[i][0]);
                    console.log(date)
                     data[i] = [
                         date,
                         this.day[i][1],
                         '<div style="min-width: 140px; padding: 5px;"><strong>' + this.day[i][2] + '</strong><br /></>' + date.toLocaleTimeString() + '<br />' + this.day[i][1] + 'm',
                     ];
                }
                data.unshift(['Time', 'Meter', {type: 'string', role: 'tooltip', 'p': {'html': true}}]);

                return data;
            }
        }
    }
</script>

<style lang="scss">
    div.rides-chart .card-body {
        padding: 0;
    }
</style>

<template>
    <div>
        <p class="mt-5"><strong>{{ $t('total') }}</strong></p>
        <p><strong>{{ new Date(ridesData.dateFrom.slice(0,10)).toLocaleDateString() }} - {{
            new Date(ridesData.dateTo.slice(0, 10)).toLocaleDateString()
          }}</strong></p>
        <b-card class="text-center bg-light">
            <b-row>
                <b-col cols="6" sm="3" class="my-2">
                    <p>
                        <v-icon name="redo-alt" scale="2"></v-icon>
                    </p>
                    <strong>{{ ridesData.noOfRides }}</strong><br/>
                    <p class="small m-0">{{ $t('stats.rides') }}</p>
                </b-col>
                <b-col cols="6" sm="3" class="my-2">
                    <p>
                        <v-icon name="arrows-alt-v" scale="2"></v-icon>
                    </p>
                    <strong>{{ formatDistance(ridesData.verticalDistance) }}</strong><br/>
                    <p class="small m-0">{{ $t('stats.vertical') }}</p>
                </b-col>
                <b-col cols="6" sm="3" class="my-2">
                    <p>
                        <v-icon name="skiing" scale="2"></v-icon>
                    </p>
                    <strong>{{ formatDistanceKm(ridesData.downhillSlopeDistance) }}</strong>
                    <p class="small m-0">{{ $t('stats.slope') }}</p>
                </b-col>
                <b-col cols="6" sm="3" class="my-2">
                    <p>
                        <v-icon name="tram" scale="2"></v-icon>
                    </p>
                    <strong>{{ formatDistanceKm(ridesData.liftDistance) }}</strong><br/>
                    <p class="small m-0">{{ $t('stats.cable') }}</p>
                </b-col>
            </b-row>
        </b-card>


        <div v-if="ridesData.days.length > 0">
            <p class="mt-5"><strong>{{ $t('days') }}</strong></p>
            <!-- Main table element -->
            <b-table show-empty
                     stacked="md"
                     :items="tableData"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     id="day-table"
                     striped
                     hover
                     @row-clicked="expandAdditionalInfo"

            >
                <template slot="actions" slot-scope="row">
                    <v-icon v-if="row.detailsShowing" name="chevron-up" scale="1"/>
                    <v-icon v-else name="chevron-down" scale="1"/>
                </template>
                <template slot="row-details" slot-scope="row">
                    <DayChart v-bind:day="row.item.rides_and_drops"/>
                </template>
            </b-table>

            <b-row class="small">
                <b-col sm="6" class="my-1 float-left">
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0"/>
                </b-col>
                <b-col sm="6" class="my-0 float-right">
                    <b-form-group horizontal label="Na stránku" :label-cols="7" label-class="text-right">
                        <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import 'vue-awesome/icons/chevron-down'
    import 'vue-awesome/icons/chevron-up'
    import 'vue-awesome/icons/check'
    import 'vue-awesome/icons/arrows-alt-v'
    import 'vue-awesome/icons/skiing'
    import 'vue-awesome/icons/tram'
    import 'vue-awesome/icons/redo-alt'
    import Icon from 'vue-awesome/components/Icon'
    import DayChart from '@/components/DayChart'

    Vue.component('v-icon', Icon);

    export default {
        name: "PeriodTable",
        props: {
            'ridesData': Object,
        },
        components: {
            DayChart,
        },
        data() {
            return {
                items: null,
                fields: [
                    {
                        key: 'date',
                        label: this.$t('table.date'),
                        sortable: true,
                        sortDirection: 'desc',
                        formatter: 'formatDate'
                    },
                    {key: 'no_of_rides', label: this.$t('table.rides'), sortable: true, 'class': 'text-center'},
                    {
                        key: 'vertical_distance',
                        label: this.$t('table.vertical'),
                        sortable: true,
                        'class': 'text-center',
                        formatter: 'formatDistance'
                    },
                    {
                        key: 'downhill_slope_distance',
                        label: this.$t('table.slope'),
                        sortable: true,
                        'class': 'text-center',
                        formatter: 'formatDistanceKm'
                    },
                    {
                        key: 'lift_distance',
                        label: this.$t('table.cable'),
                        sortable: true,
                        'class': 'text-center',
                        formatter: 'formatDistanceKm'
                    },
                    {key: 'actions', label: ''}

                ],
                currentPage: 1,
                perPage: 5,
                totalRows: this.ridesData.days.length,
                pageOptions: [5, 10, 15],
                sortBy: null,
                sortDesc: false,
                sortDirection: 'asc',
                filter: null
            }
        },
        computed: {
            tableData() {
                let data = [];
                let i;
                for (i = 0; i < this.ridesData.days.length; i++) {
                    data[i] =
                        {
                            "no_of_rides": this.ridesData.days[i].noOfRides,
                            "vertical_distance": this.ridesData.days[i].verticalDistance,
                            "downhill_slope_distance": this.ridesData.days[i].downhillSlopeDistance,
                            "lift_distance": this.ridesData.days[i].liftDistance,
                            "date": this.ridesData.days[i].date,
                            "rides_and_drops": this.ridesData.days[i].ridesAndDrops,
                            "_showDetails": false,

                        };
                }
                return data;
            },
        },
        methods: {
            formatDate(value) {
                const date = new Date(value);
                return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
            },
            formatDistance(value) {
                const formatter = new Intl.NumberFormat('cs-CZ', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return formatter.format(value) + ' m';
            },
            formatDistanceKm(value) {
                const formatter = new Intl.NumberFormat('cs-CZ', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                });
                return formatter.format(value / 1000) + ' km';
            },
            expandAdditionalInfo(row) {
                row._showDetails = !row._showDetails;
            },

        }
    }
</script>

<style lang="scss">

    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";

    table#day-table {
        font-size: 15px;
        tr:not(.b-table-details) {
            :hover {
                cursor: pointer;
            }
        }
        tr.b-table-details {
            background-color: white;
        }
        th {
            border-top: 0;
            text-transform: uppercase;
            color: $gray-600;
            font-size: 80%;
        }
    }

</style>
